import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';

import BlogPosts, { BlogPostHead, BlogPostButton } from './blogPosts.style';
import { useTranslation } from 'react-i18next';

const BlogPostsSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  dateStyle,
  buttonFillStyle,
  pageContext,
}) => {
  const { t } = useTranslation();
  const Data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              slug
              title
              author
              lang
            }
          }
        }
      }
    }
  `);

  // Filter the results in JavaScript based on pageContext.lang
  const blogPosts = Data.allMarkdownRemark.edges.filter(
    (edge) =>
      !!edge.node.frontmatter.date &&
      edge.node.frontmatter.lang === pageContext.lang
  );

  return (
    <Box {...sectionWrapper} id="news_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content={t('NEWS')} />
          <Heading
            {...secHeading}
            content={t('The latest news about Psono and password management')}
          />
        </Box>
        <Box {...row}>
          {blogPosts.map((blogPost, index) => (
            <Box {...col} key={`feature-item-${index}`}>
              <BlogPosts className="pricing_table">
                <BlogPostHead>
                  <Heading
                    content={blogPost.node.frontmatter.title}
                    {...nameStyle}
                  />
                  <Text content={blogPost.node.excerpt} {...descriptionStyle} />
                </BlogPostHead>
                <BlogPostButton>
                  <Link to={blogPost.node.frontmatter.slug}>
                    <Button title={t('MORE')} {...buttonFillStyle} />
                  </Link>
                </BlogPostButton>
                <Text
                  content={t('written_by_on', {
                    author: blogPost.node.frontmatter.author,
                    date: blogPost.node.frontmatter.date,
                  })}
                  {...dateStyle}
                />
              </BlogPosts>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

BlogPostsSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  dateStyle: PropTypes.object,
};

BlogPostsSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '100px', '100px', '140px', '160px'],
    pb: ['60px', '80px', '80px', '100px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    textAlign: 'center',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 2],
    pr: '15px',
    pl: '15px',
    mb: '30px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    mb: '0',
  },
  dateStyle: {
    fontSize: ['14px', '14px', '14px', '14px', '14px'],
    color: '#CCC',
    lineHeight: '1.75',
    textAlign: 'right',
    mb: '0',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
};

export default BlogPostsSection;
